import { template as template_07b4d1dbd3c344609cff4ecea23bebb5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_07b4d1dbd3c344609cff4ecea23bebb5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
