import { template as template_fcfc33b43eec4822b11c27a7f6db4b1a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_fcfc33b43eec4822b11c27a7f6db4b1a(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
