import { template as template_a4a6d95b600d40dbb6b8e6e164af096a } from "@ember/template-compiler";
const FKText = template_a4a6d95b600d40dbb6b8e6e164af096a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
