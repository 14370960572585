import { template as template_a5e75bd3a3da4a0bb5f0dcd472ac12fc } from "@ember/template-compiler";
const FKControlMenuContainer = template_a5e75bd3a3da4a0bb5f0dcd472ac12fc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
